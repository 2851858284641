<template>
  <div class="resetpassword-containertop">
    <div class="bg">
      <nav-bar :title="'設置密碼'"></nav-bar>
      <div class="resetpassword-form">
        <div>
          <van-form>
            <div>
              <van-field
                v-model="form.password"
                :type="isPassword ? 'password' : 'text'"
                @click-right-icon="isPassword = !isPassword"
                :placeholder="$t('請輸入6-15位含字母和數字密碼')"
              >
                <template #right-icon>
                  <div>
                    <img
                      v-if="isPassword"
                      style="width: 20px"
                      src="@/assets/icons/eyes.png"
                      alt=""
                    />
                    <img
                      v-else
                      style="width: 20px"
                      src="@/assets/icons/eye-open.png"
                      alt=""
                    />
                  </div>
                </template>
              </van-field>
            </div>
            <div>
              <van-field
                v-model="form.repassword"
                :type="isPassword1 ? 'password' : 'text'"
                @click-right-icon="isPassword1 = !isPassword1"
                :placeholder="$t('請再次確認密碼')"
              >
                <template #right-icon>
                  <div>
                    <img
                      v-if="isPassword1"
                      style="width: 20px"
                      src="@/assets/icons/eyes.png"
                      alt=""
                    />
                    <img
                      v-else
                      style="width: 20px"
                      src="@/assets/icons/eye-open.png"
                      alt=""
                    />
                  </div>
                </template>
              </van-field>
              <!-- <div class="error" v-if="verifyobj.password">請輸入密码</div> -->
            </div>
            <div style="margin: 16px;">
              <van-button
                @click="getNextForgotpassword"
                class="Forgotpassword-btn"
                block
                type="info"
                native-type="submit"
                color="rgba(144, 209, 46, 1)"
                >{{ $t("保存") }}</van-button
              >
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLogin } from "@/api/login";
import { setToken } from "@/utils/token";
import JSEncrypt from "jsencrypt";
import {
  getCaptchaNew,
  getvalidateEmail,
  getRegisterNew,
  validatePhoneCaptcha,
  forgetPwdUpdatePwd
} from "@/api/user";

export default {
  data() {
    return {
      form: {
        password: "",
        phone: "",
        email: "",
        phoneCaptcha: "",
        areaCode: null,
        repassword: ""
      },
      active: "1",
      isPassword: true,
      isEmail: false,
      isEyes: false,
      isPassword1: true,
      columns: [
        {
          value: "1",
          label: "HK(+852)"
        },
        {
          value: "2",
          label: "MO(+853)"
        }
      ],
      show: false,
      selectPhone: "1",
      phoneLabel: "HK(+852)",
      isRegister: false,
      verifyobj: {
        userName: "",
        password: ""
      },
      verifyList: []
    };
  },
  created() {
    //  if(!this.$route.query.phoneCaptcha){
    //   this.$router.push('/rorgotPassword')
    //  }
  },
  methods: {
    handleLoginTab() {
      this.verifyList = [];
      this.validatoForm();
    },
    gotoRegister() {
      this.isRegister = false;
      this.$router.push("/register");
    },
    // 注册
    openRegister() {
      // this.isRegister = true;
      this.$router.push("/register");
    },
    handleAreaCode(item) {
      this.selectPhone = item.value;
      this.show = false;
      this.form.areaCode = item.value == "1" ? "+852" : "+853";
      this.phoneLabel = item.label;
    },
    validatorPhone(val) {},
    validatorEmail(val) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(val);
    },
    onChange(val) {
      this.form.areaCode = val;
      this.show = false;
    },
    async getNextForgotpassword() {
      const regxEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      const regxPws = /^(?=.*\d)(?=.*[a-zA-Z]).+$/;
      if (
        !regxPws.test(this.form.password) ||
        !regxPws.test(this.form.repassword)
      ) {
        return this.$toast(this.$t("請輸入6-15位含字母和數字密碼"));
      }
      if (this.form.password !== this.form.repassword) {
        return this.$toast(this.$t("兩次密碼不一致"));
      }


      this.form.areaCode =this.$route.query.areaCode.replace(/\s/g, '+');
      this.form.email = this.$route.query.email;
      this.form.phone = this.$route.query.phone;
      this.form.phoneCaptcha = this.$route.query.phoneCaptcha;
      const res = await forgetPwdUpdatePwd(this.form);
      if (res.resultID == 1200) {
        this.$toast(this.$t('修改成功'));
        this.$router.push("/login");
      }
    }
  }
};
</script>

<style lang="scss">
.resetpassword-containertop {
  .bg {
    height: 80px;
    background: linear-gradient(
      180deg,
      rgba(144, 209, 46, 0.3) 0%,
      rgba(144, 209, 46, 0) 100%
    );
    border-radius: 0px 0px 0px 0px;
  }
  .logo {
    width: 166px;
    margin: 46px 0 40px 32px;
  }
  .van-field {
    background-color: transparent;
    padding: 12px;
    border-bottom: 2px solid #ebebeb;
    &::after {
      display: none;
    }
  }
  .van-tabs {
    width: 60%;
  }
  .van-tabs__nav {
    background-color: transparent;
    height: auto;
    .van-tabs__line {
      display: none;
    }
    .van-tab:first-child {
      border-right: 1px solid #bdbdbd;
    }
    .van-tab__text {
      font-size: 18px;
    }
  }
  .arrow {
    width: 16px;
    height: 16px;
  }
  .phone {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #1a1a1a;
    margin: 12px 0px 20px 14px;
  }
  .resetpassword-form {
    padding: 0 22px;
    padding-top: 80px;
    .error {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #f82207;
      line-height: 18px;
      padding-left: 12px;
      margin-top: 1px;
    }
    .Forgotpassword-btn {
      border-radius: 12px;
      margin-top: 24px;
    }
    .Verification-btn {
      color: #3491fa;
    }
  }
}
.Forgot {
  display: flex;
  justify-content: end;
  font-size: 15px;
  color: #1a1a1a;
  line-height: 24px;
  margin-top: 20px;
}
.register {
  text-align: center;
  color: #90d12e;
}
.titleArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 0;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;

  .close {
    width: 20px;
  }
}
.phone-list {
  padding: 20px 16px;
  .actvie {
    border: 2px solid #90d12e !important;
  }
  .phone-item {
    padding: 20px;
    position: relative;
    background: #f8f8f8;
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 12px;
    border: 2px solid transparent;
    .phone-text {
      font-size: 16px;
      color: #1a1a1a;
      line-height: 16px;
      text-align: center;
    }
    .phone-check {
      position: absolute;
      width: 24px;
      bottom: -1px;
      right: -1px;
    }
  }
}
.register-dialog {
  padding: 24px;
}
.register-text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #666666;
  line-height: 24px;
  text-align: center;
  span {
    color: #3491fa;
  }
}
.register-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Cancel,
  .Confirm {
    flex: 1;
    text-align: center;
    margin-top: 32px;
  }
  .Cancel {
    background: #eeeeee;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
    margin-right: 12px;
  }
  .Confirm {
    font-size: 16px;
    color: #ffffff;
    background-color: #90d12e;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
  }
}
</style>
